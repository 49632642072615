// routes
import { PATH_DASHBOARD } from "../../../routes/paths";
// components
import SvgIconStyle from "../../../components/SvgIconStyle";
import Label from "../../../components/Label";
import { useTranslation } from "react-i18next";
// ----------------------------------------------------------------------

const getIcon = (name) => (
  <SvgIconStyle
    src={`/static/icons/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);

const ICONS = {
  dashboard: getIcon("ic_dashboard"),
  supervisor: getIcon("ic_supervisor"),
  agent: getIcon("ic_agent"),
  customer: getIcon("ic_customer"),
  configuration: getIcon("ic_configuration"),
  security_and_limit: getIcon("ic_security_and_limit"),
  user: getIcon("ic_user"),
};

const NavConfig = () => {
  const { t } = useTranslation();
  const navConfig = [
    // GENERAL
    // ----------------------------------------------------------------------
    {
      subheader: "MANAGEMENT",
      items: [
        {
          title: t("sideBar.overview"),
          path: PATH_DASHBOARD.general.app,
          icon: ICONS.dashboard,
        },
        {
          title: t("sideBar.supervisor"),
          path: PATH_DASHBOARD.supervisor.root,
          icon: ICONS.supervisor,
          children: [
            { title: t("sideBar.add_supervisor"), path: PATH_DASHBOARD.supervisor.add },
            { title: t("sideBar.list_supervisor"), path: PATH_DASHBOARD.supervisor.list },
          ],
        },
        {
          title: t("sideBar.agent"),
          path: PATH_DASHBOARD.agent.root,
          icon: ICONS.agent,
          children: [
            { title: t("sideBar.add_agent"), path: PATH_DASHBOARD.agent.add },
            { title: t("sideBar.list_agent"), path: PATH_DASHBOARD.agent.list },
          ],
        },
        {
          title: t("sideBar.customer"),
          path: PATH_DASHBOARD.customer.root,
          icon: ICONS.customer,
          children: [
            { title: t("sideBar.add_customer"), path: PATH_DASHBOARD.customer.add },
            { title: t("sideBar.list_customer"), path: PATH_DASHBOARD.customer.list },
          ],
        },
      ],
    },

    {
      subheader: "TRANSACTIONS",
      items: [
        {
          title: t("sideBar.configuration"),
          path: PATH_DASHBOARD.configuration.root,
          icon: ICONS.configuration,
          children: [
            { title: t("sideBar.exchange_rate"), path: PATH_DASHBOARD.configuration.exchange_rate },
            { title: t("sideBar.feegenerale"), path: PATH_DASHBOARD.configuration.fee },
          ],
        },
        {
          title: t("sideBar.security_and_limit"),
          path: PATH_DASHBOARD.security_and_limit.root,
          icon: ICONS.security_and_limit,
          children: [
            { title: t("sideBar.limit_transaction_generale"), path: PATH_DASHBOARD.security_and_limit.limit_transaction_generale },
          ],
        },
      ],
    },

    // PARAMETTRE
    // ----------------------------------------------------------------------
    {
      subheader: "Paramettre",
      items: [
        // USER
        {
          title: t("sideBar.user"),
          path: PATH_DASHBOARD.user.root,
          icon: ICONS.user,
          children: [
            { title: t("sideBar.edit"), path: PATH_DASHBOARD.user.account },
          ],
        },
      ],
    },
  ];
  return navConfig;
};

export default NavConfig;
