import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
// slices
import generaleReducer from "./slices/generale";
import selectiontirageReducer from "./slices/selectiontirage";
import ticketReducer from "./slices/ticket";
// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: "root",
  storage,
  keyPrefix: "redux-",
  whitelist: [],
};

const rootReducer = combineReducers({
  generale: generaleReducer,
  selectiontirage: selectiontirageReducer,
  ticket: ticketReducer,
});

export { rootPersistConfig, rootReducer };
