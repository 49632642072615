import React, { useState, useEffect, useRef } from "react";
import { TextField, Button, IconButton, Box, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { getRepports } from "../../../../../redux/slices/generale";
import { fDateOnly, fDate } from "../../../../../utils/formatTime";
import Iconify from "../../../../../components/Iconify";
import useAuth from "../../../../../hooks/useAuth";

const RepportPartiel = (open) => {
  const { user } = useAuth();
  const [date, setDate] = useState(new Date());
  const [repportPartiel, setRepportPartiel] = useState(null);

  const fetchData = async () => {
    try {
      const data = await getRepports(
        fDateOnly(date, "yyyy-MM-dd"),
        fDateOnly(date, "yyyy-MM-dd"),
        0,
        0
      );
      setRepportPartiel(data);
    } catch (error) {
      console.error("Erreur:", error);
    }
  };

  useEffect(() => {
    if (open) {
      fetchData();
    }
  }, [open, date]);

  const handlePrint = () => {
    window.print();
  };

  return (
    <Box>
      <Box textAlign="center" mb={2}>
        <Typography variant="h6">Fich ki genyen</Typography>
        <Button variant="outlined" onClick={handlePrint}>
          <Iconify icon={"eva:printer-fill"} />
        </Button>
      </Box>
      <Typography variant="h6" align="center">
        Rapport Partiel
      </Typography>

      <Box sx={{ display: "flex", gap: 2, py: 2 }}>
        <DatePicker
          label="Date"
          value={date}
          onChange={(newValue) => setDate(newValue)}
          renderInput={(params) => <TextField {...params} />}
        />
        <IconButton onClick={fetchData} aria-label="search">
          <Iconify icon={"eva:search-fill"} />
        </IconButton>
      </Box>

      <Box>
        <Box sx={{ display: "flex", gap: 20 }}>
          <Typography variant="body2">Tirage: </Typography>
          <Typography variant="body2">{repportPartiel?.tirage}</Typography>
        </Box>
        <Box sx={{ display: "flex", gap: 21 }}>
          <Typography variant="body2">Date: </Typography>
          <Typography variant="body2">{fDate(date)}</Typography>
        </Box>
        <Box sx={{ display: "flex", gap: 21.6 }}>
          <Typography variant="body2">POS: </Typography>
          <Typography variant="body2">{user?.imei}</Typography>
        </Box>
        <Box sx={{ display: "flex", gap: 18.3 }}>
          <Typography variant="body2">Vendeur: </Typography>
          <Typography variant="body2">
            {user?.first_name} {user?.last_name}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", gap: 15.5 }}>
          <Typography variant="body2">Fiche vendu: </Typography>
          <Typography variant="body2">
            {repportPartiel?.quantite || 0}
          </Typography>
        </Box>

        <Box sx={{ display: "flex", gap: 20.3 }}>
          <Typography variant="body2">Vente: </Typography>
          <Typography variant="body2">
            {Math.round(repportPartiel?.vente) || 0}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", gap: 16.2 }}>
          <Typography variant="body2">Commision: </Typography>
          <Typography variant="body2">
            {Math.round(repportPartiel?.commission) || 0}
          </Typography>
        </Box>
      </Box>

      <Box textAlign="center" mt={4}>
        <Typography variant="h6">Fich ki genyen</Typography>
        <Button variant="outlined" onClick={handlePrint}>
          <Iconify icon={"eva:printer-fill"} />
        </Button>
      </Box>
    </Box>
  );
};

export default RepportPartiel;
