export const getValidedBoule = (boule, user = null) => {
  let valid = true;
  let option = 1;
  let symb = ".";
  let lotto;
  let formula = null;

  if (boule.toLowerCase() === "bp") {
    formula = "BP";
    return { valid, lotto, option, symb, formula, boule_: boule };
  } else if (boule.toLowerCase() === "rv") {
    formula = "rv";
    return { valid, lotto, option, symb, formula, boule_: boule };
  } else if (boule.toLowerCase() === "l3") {
    formula = "l3";
    return { valid, lotto, option, symb, formula, boule_: boule };
  } else if (boule.toLowerCase() === "ma") {
    formula = "ma";
    return { valid, lotto, option, symb, formula, boule_: boule };
  } else if (boule.toLowerCase() === "l4") {
    formula = "l4";
    return { valid, lotto, option, symb, formula, boule_: boule };
  } else if (boule.toLowerCase() === "p0") {
    formula = "p0";
    return { valid, lotto, option, symb, formula, boule_: boule };
  } else if (boule.toLowerCase() === "p1") {
    formula = "p1";
    return { valid, lotto, option, symb, formula, boule_: boule };
  } else if (boule.toLowerCase() === "p2") {
    formula = "p2";
    return { valid, lotto, option, symb, formula, boule_: boule };
  } else if (boule.toLowerCase() === "p3") {
    formula = "p3";
    return { valid, lotto, option, symb, formula, boule_: boule };
  } else if (boule.toLowerCase() === "p4") {
    formula = "p4";
    return { valid, lotto, option, symb, formula, boule_: boule };
  } else if (boule.toLowerCase() === "p5") {
    formula = "p5";
    return { valid, lotto, option, symb, formula, boule_: boule };
  } else if (boule.toLowerCase() === "p6") {
    formula = "p6";
    return { valid, lotto, option, symb, formula, boule_: boule };
  } else if (boule.toLowerCase() === "p7") {
    formula = "p7";
    return { valid, lotto, option, symb, formula, boule_: boule };
  } else if (boule.toLowerCase() === "p8") {
    formula = "p8";
    return { valid, lotto, option, symb, formula, boule_: boule };
  } else if (boule.toLowerCase() === "p9") {
    formula = "p9";
    return { valid, lotto, option, symb, formula, boule_: boule };
  } else if (boule?.length === 5 && boule[boule.length - 1] === "+") {
    formula = "l4c";
    return { valid, lotto, option, symb, formula, boule_: boule };
  } else if (boule?.length === 5 && boule[boule.length - 1] === "m") {
    formula = "mac";
    return { valid, lotto, option, symb, formula, boule_: boule };
  }

  if (boule?.length === 2) {
    lotto = "BO";
  } else if (boule?.length === 3 && boule[0].toLowerCase() === "p") {
    lotto = "P2";
  } else if (boule?.length === 3 && boule[0].toLowerCase() === "n") {
    lotto = "NO";
  } else if (boule?.length === 6 && boule[0].toLowerCase() === "p") {
    lotto = "P5";
  } else if (boule?.length === 3) {
    lotto = "L3";
  } else if (boule?.length === 4 && boule[0].toLowerCase() === "b") {
    lotto = "L3B";
    if (new Set(boule?.slice(1)).size < boule?.slice(1)?.length) {
      valid = false;
    }
  }else if (boule?.length === 4 && boule[0].toLowerCase() === "s") {
    lotto = "L3S";
    if (new Set(boule?.slice(1)).size < boule?.slice(1)?.length) {
      valid = false;
    }
  } else if (boule?.length === 4) {
    lotto = "MA";
    if (
      !user?.mariageXnXIn &&
      boule.substring(0, 2) === boule.substring(2, 4)
    ) {
      valid = false;
    }
  } else if (boule?.length === 6) {
    if (boule[boule.length - 1]  === ',') {
      console.log('Reconnu comme P5B');
      lotto = 'P5B';
      boule = boule.slice(0, -1);
   
      valid = true;
    }else if (boule[boule.length - 1] === '.') {
      console.log('Reconnu comme P5S');
      lotto = 'P5S';
     
      boule = boule.slice(0, -1);
      valid = true;
    } else if (/^\d{6}$/.test(boule)) {
      // Tous les caractères sont des chiffres
      console.log('Reconnu comme un numéro complet de 6 chiffres');
      lotto = 'TRP';
      valid = true;
    }else if (['0', '1', '2', '3'].includes(boule[0]) && boule[1] === '.') {
      symb = boule[1]; // Le premier caractère devient le symbole
      option=boule[0];
      lotto = 'PL';    // Définition de lotto comme 'PL'
      valid = true;    // Marquage comme valide
      console.log('Reconnu comme PL');
  } 
    else {
      // Condition pour L4
      option = boule?.slice(-1);
      symb = boule?.slice(-2, -1);
      lotto = 'L4';
    
      if (!['1', '2', '3', '0'].includes(option)) {
        valid = false;
      }
    
      if (!user?.l4XnXIn && boule.substring(0, 2) === boule.substring(2, 4)) {
        valid = false;
      }
    }
  } else if (boule?.length === 5 && boule[0].toLowerCase() === "b") {
    lotto = "L4B";
    if (new Set(boule?.slice(1)).size < boule?.slice(1)?.length) {
      valid = false;
    }
  }else if (boule?.length === 5 && boule[0].toLowerCase() === "s") {
    lotto = "L4S";
    if (new Set(boule?.slice(1)).size < boule?.slice(1)?.length) {
      valid = false;
    }
  } else if (boule?.length === 7) {
    option = boule?.slice(-1);
    lotto = "L5";
    if (!["1", "2", "3", "0"].includes(option)) {
      valid = false;
    }
  } else {
    lotto = null;
    valid = false;
  }

  return { valid, lotto, option, symb, formula, boule_: boule };
};

export function formatBoule(boule, lotto, option = '') {
  switch (lotto) {
      case 'MA':
          return `${boule.slice(0, 2)}*${boule.slice(2)}`;
      case 'L3B':
          return `${boule} (${lotto})`;
      case 'L3S':
        return `${boule} (${lotto})`;
      case 'L4':
          return `${boule} (${option})`;
      case 'L5':
          return `${boule} (${option})`;
      case 'L4B':
          return `${boule} (${lotto})`;
      case 'L4S':
        return `${boule} (${lotto})`;
        case 'P5S':
          return `${boule} (${lotto})`;
      case 'P5B':
        return `${boule} (${lotto})`;
        case 'NO':
          return `${boule} (${lotto})`;
        case 'PL':
          return `${boule?.substring(0, 2)}-${boule?.substring(2)}(${lotto}o${option})`;
      case 'P2':
          return `${boule}P`;
      case 'P5':
          return `${boule}P`;
    case 'TRP':
      return `${boule?.substring(0, 2)}-${boule?.substring(2, 4)}-${boule?.substring(4)}(${lotto})`;
      default:
          return boule;
  }
}

export function formatAmount(montant) {
  let mnt = parseFloat(montant)
    .toFixed(2)
    .replace(/\d(?=(\d{3})+\.)/g, "$&,");
  return mnt;
}

export function reverse_number(num) {
  const numStr = num.toString();
  if (numStr.length <= 2) {
    return numStr.split("").reverse().join("");
  } else if (numStr.length === 3) {
    return numStr[0] + numStr.slice(1, 3).split("").reverse().join("");
  } else if (numStr.length === 4) {
    return numStr.slice(2, 4) + numStr.slice(0, 2);
  } else {
    return numStr[0] + numStr.slice(3, 5) + numStr.slice(1, 3);
  }
}

export const transformedBoule = (boule, lotto) => {
  return lotto === "L4" || lotto === "L5"
    ? boule.slice(0, -2)
    : lotto === "P2" || lotto === "P5" || lotto === "L3B" || lotto === "L4B" || lotto === "L3S" || lotto === "L4S" || lotto === "NO"
    ? boule.slice(1)
    :lotto === 'P5S' || lotto === 'P5B'
    ? boule.slice(0,-1)
    :lotto === 'PL'
    ? boule.slice(-4)
    : boule;
};
export const restoreTransformedBoule = (
  boule,
  lotto,
  option = "",
  prefixe = ""
) => {
  console.log("Données avant transformation :", {
    boule,
    lotto,
    option,
    prefixe,
  });
  return lotto === "L4" || lotto === "L5"
    ? boule + "." + option
    : lotto === "P2" || lotto === "P5" || lotto === "L3B" || lotto === "L4B" || lotto === "L3S" || lotto === "L4S"
    ? prefixe + boule
    : lotto === 'PL'
    ? option +'.' +boule
    : lotto ==='P5B'
    ? boule + ','
    : lotto ==='P5S'
    ? boule + '.'
    : lotto === 'NO'
    ? prefixe +boule
    : boule;
};

export const isValidNumber = (value) => {
  return /^\d+$/.test(value);
};

export const isBouleExist = (boule, bets, selectedTirage) => {
  const existingData = bets?.find(
    (data) => data.boule === boule && data.tirage === selectedTirage
  );

  return existingData ? existingData.id : -1;
};

export const isBoulePaire = (boule) => {
  const length = boule.toString().length;

  if (length === 2) {
    return boule[0] === boule[1];
  } else if (length === 3) {
    return boule[1] === boule[2];
  } else if (length === 4) {
    return (
      boule[0] === boule[1] && boule[1] === boule[2] && boule[2] === boule[3]
    );
  } else if (length === 5) {
    return (
      boule[1] === boule[2] && boule[2] === boule[3] && boule[3] === boule[4]
    );
  }

  return false;
};

export function getLottoName(lotto) {
  switch (lotto) {
    case "MA":
      return "Mariage";
    case "BO":
      return "Borlette";
    case "P2":
      return "Pick2";
    case "P5":
      return "Pick5";
    case "L3B":
      return "Lotto3Box";
      case 'NO':
        return 'Numero';
      case 'P5S':
        return '5Straith';
      case 'P5B':
        return '5Box';
        case 'PL':
          return 'Pale';  
  
        case 'TRP':
          return 'Tripleta'; 
    case "L3":
      return "Lotto3";
    case "L4":
      return "Lotto4";
      case 'L3S':
        return '3Straith';
        case 'L4B':
          return '4Box';
      case 'L4S':
        return '4Straith';
    case "L5":
      return "Lotto5";
    default:
      return "Unknown";
  }
}

export const generateBoulePaire = () => {
  const boulesPaires = [];
  for (let i = 0; i <= 9; i++) {
    const boulePaire = `${i}${i}`;
    if (!boulesPaires.includes(String(boulePaire))) {
      boulesPaires.push(String(boulePaire));
    }
  }
  return boulesPaires;
};

export const generateBoulePoint = (point) => {
  const boulesPoint = [];

  for (let i = 0; i <= 9; i++) {
    const nouvelleBoule = `${i}${point}`;
    if (!boulesPoint.includes(nouvelleBoule)) {
      boulesPoint.push(nouvelleBoule);
    }
  }

  return boulesPoint;
};

export const generateBouleReverce = (bets) => {
  const boulesReverces = [];
  for (const betsItem of bets) {
    const { boule, lotto, option } = betsItem;
    const bouleReverce = restoreTransformedBoule(
      reverse_number(transformedBoule(boule, lotto)),
      lotto,
      ["L4", "L5",'PL'].includes(lotto) ? option : "",
      lotto === "P2"
        ? "p"
        : lotto === "P5"
        ? "p"
        : lotto === "L3B"
        ? "b"
        : lotto === "L4B"
        ? "b"
        : lotto === "L4S"
        ? "s"
        : ""
    );
    if (!isBoulePaire(transformedBoule(boule, lotto))) {
      if (!boulesReverces.includes(String(bouleReverce))) {
        boulesReverces.push(String(bouleReverce));
      }
    }
  }
  return boulesReverces;
};

export const generateLotto3Auto = (bets) => {
  const boulesL3 = [];
  for (const boule of bets) {
    for (let i = 0; i <= 9; i++) {
      const bouleL3 = `${i}${boule.boule}`;
      if (boule.lotto === "BO" && !boulesL3.includes(bouleL3)) {
        boulesL3.push(bouleL3);
      }
    }
  }
  return boulesL3;
};
export const generateMariageAuto = (bets) => {
  const boulesMariages = [];

  for (const boule1 of bets) {
    for (const boule2 of bets) {
      if (
        !boulesMariages.includes(`${boule1.boule}${boule2.boule}`) &&
        !boulesMariages.includes(`${boule2.boule}${boule1.boule}`)
      ) {
        const nouvelleBouleMariage = `${boule1.boule}${boule2.boule}`;
        if (
          boule1.lotto === "BO" &&
          boule2.lotto === "BO" &&
          !boulesMariages.includes(nouvelleBouleMariage)
        ) {
          boulesMariages.push(nouvelleBouleMariage);
        }
      }
    }
  }

  return boulesMariages;
};

export const generateL4Auto = (bets, option) => {
  const boulesL4 = [];

  for (const boule1 of bets) {
    for (const boule2 of bets) {
      const nouvelleBouleL4 = `${boule1.boule}${boule2.boule}.${option}`;
      if (
        boule1.lotto === "BO" &&
        boule2.lotto === "BO" &&
        !boulesL4.includes(nouvelleBouleL4)
      ) {
        boulesL4.push(nouvelleBouleL4);
      }
    }
  }

  return boulesL4;
};

export const generatePermutationsL4 = (input) => {
  const inputStr = input.toString();
  const boule1 = inputStr.slice(0, 2);
  const boule2 = inputStr.slice(2);

  const boules = [
    inputStr,
    boule2 + boule1,
    boule1.split("").reverse().join("") + boule2,
    boule2 + boule1.split("").reverse().join(""),
    boule1 + boule2.split("").reverse().join(""),
    boule2.split("").reverse().join("") + boule1,
    boule1.split("").reverse().join("") + boule2.split("").reverse().join(""),
    boule2.split("").reverse().join("") + boule1.split("").reverse().join(""),
  ];

  return boules;
};

export const generateAndHandleL4Complet = (boule, option) => {
  const cleanedBoule = boule.replace("+", "");
  const specificPermutations = [
    cleanedBoule + `_${option}`,
    cleanedBoule[3] +
      cleanedBoule[2] +
      cleanedBoule[1] +
      cleanedBoule[0] +
      `_${option}`,
    cleanedBoule[1] +
      cleanedBoule[0] +
      cleanedBoule[2] +
      cleanedBoule[3] +
      `_${option}`,
    cleanedBoule[3] +
      cleanedBoule[2] +
      cleanedBoule[0] +
      cleanedBoule[1] +
      `_${option}`,
    cleanedBoule[0] +
      cleanedBoule[2] +
      cleanedBoule[1] +
      cleanedBoule[3] +
      `_${option}`,
    cleanedBoule[2] +
      cleanedBoule[3] +
      cleanedBoule[0] +
      cleanedBoule[1] +
      `_${option}`,
    cleanedBoule[1] +
      cleanedBoule[2] +
      cleanedBoule[0] +
      cleanedBoule[3] +
      `_${option}`,
    cleanedBoule[2] +
      cleanedBoule[3] +
      cleanedBoule[1] +
      cleanedBoule[0] +
      `_${option}`,
  ];

  return specificPermutations;
};

export const generateAndHandleMaComplet = (boule) => {
  const cleanedBoule = boule.replace("m", "");

  const specificPermutations = [
    cleanedBoule,
    cleanedBoule[0] + cleanedBoule[1] + cleanedBoule[3] + cleanedBoule[2],
    cleanedBoule[1] + cleanedBoule[0] + cleanedBoule[2] + cleanedBoule[3],
    cleanedBoule[1] + cleanedBoule[0] + cleanedBoule[3] + cleanedBoule[2],
  ];

  return specificPermutations;
};

export const convertToDict = (bets) => {
  const result = { ficheData: [] };
  const tempDict = {};

  bets.forEach((item) => {
    const { tirage, boule, lotto, option, montant } = item;
    if (!tempDict[tirage]) {
      tempDict[tirage] = { tirage, bouleLists: [] };
    }

    const bouleFix = transformedBoule(boule, lotto);
    tempDict[tirage].bouleLists.push({
      boule: String(bouleFix),
      lotto,
      option: String(option),
      montant: parseFloat(montant),
    });
  });

  result.ficheData = Object.values(tempDict);
  return result;
};
