import React, { useState, useEffect, useRef } from "react";
import {
  TextField,
  Button,
  IconButton,
  Box,
  Typography,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { getRepports } from "../../../../../redux/slices/generale";
import { fDateOnly, fDate } from "../../../../../utils/formatTime";
import { useSelector } from "react-redux";
import Iconify from "../../../../../components/Iconify";
import useAuth from "../../../../../hooks/useAuth";

const RepportFinTirage = (open) => {
  const { user } = useAuth();
  const tirages = useSelector((state) => state.generale.tirages);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [selectedTirage, setSelectedTirage] = useState("");
  const [repportFt, setRepportFt] = useState(null);

  const fetchData = async () => {
    try {
      const data = await getRepports(
        fDateOnly(startDate, "yyyy-MM-dd"),
        fDateOnly(endDate, "yyyy-MM-dd"),
        selectedTirage,
        0
      );
      setRepportFt(data);
    } catch (error) {
      console.error("Erreur:", error);
    }
  };

  useEffect(() => {
    if (open) {
      fetchData();
    }
  }, [open]);
  const handlePrint = () => {
    window.print();
  };

  return (
    <Box>
      <Box textAlign="center" mb={2}>
        <Button variant="outlined" onClick={handlePrint}>
          <Iconify icon={"eva:printer-fill"} />
        </Button>
      </Box>
      <Typography variant="h6" align="center">
        Rapport Fin Tirage
      </Typography>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          py: 1,
          backgroundColor: "#f5f5f5",
          borderRadius: "8px",
          boxShadow: 3,
        }}
      >
        <Box>
          <Typography
            variant="h3"
            color="textPrimary"
            sx={{
              fontWeight: "500",
              color: repportFt?.gain > 0 ? "green" : "red",
              textAlign: "center",
            }}
          >
            {Math.round(repportFt?.gain) || 0}
          </Typography>
          <Typography variant="h6" color="textSecondary">
            Balance
          </Typography>
        </Box>
      </Box>

      <Box sx={{ display: "flex", gap: 2, py: 2 }}>
        <DatePicker
          label="Debut"
          value={startDate}
          onChange={(newValue) => setStartDate(newValue)}
          renderInput={(params) => <TextField {...params} />}
        />
        <DatePicker
          label="Fin"
          value={endDate}
          onChange={(newValue) => setEndDate(newValue)}
          renderInput={(params) => <TextField {...params} />}
        />
        <FormControl sx={{ minWidth: 150 }}>
          <InputLabel id="tirage-select-label">Tirage</InputLabel>
          <Select
            value={selectedTirage}
            label="Tirage"
            onChange={(e) => setSelectedTirage(e.target.value)}
          >
            {tirages.map((tirage, index) => (
              <MenuItem key={index} value={tirage.id}>
                {tirages.find((tr) => tr?.id === tirage.id)?.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <IconButton onClick={fetchData} aria-label="search">
          <Iconify icon={"eva:search-fill"} />
        </IconButton>
      </Box>

      <Box>
        <Box sx={{ display: "flex", gap: 20 }}>
          <Typography variant="body2">Tirage: </Typography>
          <Typography variant="body2">{repportFt?.tirage}</Typography>
        </Box>
        <Box sx={{ display: "flex", gap: 21 }}>
          <Typography variant="body2">Date: </Typography>
          <Typography variant="body2">
            {fDate(startDate)} - {fDate(endDate)}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", gap: 21.6 }}>
          <Typography variant="body2">POS: </Typography>
          <Typography variant="body2">{user?.imei}</Typography>
        </Box>
        <Box sx={{ display: "flex", gap: 18.3 }}>
          <Typography variant="body2">Vendeur: </Typography>
          <Typography variant="body2">
            {user?.first_name} {user?.last_name}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", gap: 15.5 }}>
          <Typography variant="body2">Fiche vendu: </Typography>
          <Typography variant="body2">{repportFt?.quantite || 0}</Typography>
        </Box>

        <Box sx={{ display: "flex", gap: 13.7 }}>
          <Typography variant="body2">Fiche Gagnant: </Typography>
          <Typography variant="body2">{repportFt?.fgagnant || 0}</Typography>
        </Box>

        <Box sx={{ display: "flex", gap: 20.2 }}>
          <Typography variant="body2">Vente: </Typography>
          <Typography variant="body2">
            {Math.round(repportFt?.vente) || 0}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", gap: 18.3 }}>
          <Typography variant="body2">Paiment: </Typography>
          <Typography variant="body2">
            {Math.round(repportFt?.perte) || 0}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", gap: 16 }}>
          <Typography variant="body2">Commision: </Typography>
          <Typography variant="body2">
            {Math.round(repportFt?.commission) || 0}
          </Typography>
        </Box>

        <Box sx={{ display: "flex", gap: 18.6 }}>
          <Typography variant="body2">Balance: </Typography>
          <Typography
            variant="body2"
            sx={{
              fontWeight: "500",
              color: repportFt?.gain > 0 ? "green" : "red",
            }}
          >
            {Math.round(repportFt?.gain) || 0}
          </Typography>
        </Box>
      </Box>

      <Box textAlign="center" mt={4}>
        <Button variant="outlined" onClick={handlePrint}>
          <Iconify icon={"eva:printer-fill"} />
        </Button>
      </Box>
    </Box>
  );
};

export default RepportFinTirage;
