import React, { useState, useEffect, useRef } from "react";
import {
  TextField,
  Button,
  IconButton,
  Box,
  Typography,
  Grid,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { getRepports } from "../../../../../redux/slices/generale";
import { fDateOnly, fDate } from "../../../../../utils/formatTime";
import Iconify from "../../../../../components/Iconify";
import useAuth from "../../../../../hooks/useAuth";

const RepportFicheGagnant = (open) => {
  const { user } = useAuth();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [repportFg, setRepportFt] = useState(null);

  const fetchData = async () => {
    try {
      const data = await getRepports(
        fDateOnly(startDate, "yyyy-MM-dd"),
        fDateOnly(endDate, "yyyy-MM-dd"),
        0,
        1
      );
      setRepportFt(data);
    } catch (error) {
      console.error("Erreur:", error);
    }
  };

  useEffect(() => {
    if (open) {
      fetchData();
    }
  }, [open]);

  const handlePrint = () => {
    window.print();
  };

  return (
    <Box>
      <Box textAlign="center" mb={2}>
        <Button variant="outlined" onClick={handlePrint}>
          <Iconify icon={"eva:printer-fill"} />
        </Button>
      </Box>
      <Typography variant="h6" align="center">
        Rapport Fiche Gagnant
      </Typography>

      <Box sx={{ display: "flex", gap: 2, py: 2 }}>
        <DatePicker
          label="Debut"
          value={startDate}
          onChange={(newValue) => setStartDate(newValue)}
          renderInput={(params) => <TextField {...params} />}
        />
        <DatePicker
          label="Fin"
          value={endDate}
          onChange={(newValue) => setEndDate(newValue)}
          renderInput={(params) => <TextField {...params} />}
        />
        <IconButton onClick={fetchData} aria-label="search">
          <Iconify icon={"eva:search-fill"} />
        </IconButton>
      </Box>

      <Box>
        <Box sx={{ display: "flex", gap: 20 }}>
          <Typography variant="body2">Tirage: </Typography>
          <Typography variant="body2">{repportFg?.tirage}</Typography>
        </Box>
        <Box sx={{ display: "flex", gap: 21 }}>
          <Typography variant="body2">Date: </Typography>
          <Typography variant="body2">
            {fDate(startDate)} - {fDate(endDate)}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", gap: 21.6 }}>
          <Typography variant="body2">POS: </Typography>
          <Typography variant="body2">{user?.imei}</Typography>
        </Box>
        <Box sx={{ display: "flex", gap: 18.3 }}>
          <Typography variant="body2">Vendeur: </Typography>
          <Typography variant="body2">
            {user?.first_name} {user?.last_name}
          </Typography>
        </Box>

        <Box sx={{ display: "flex", gap: 20.2 }}>
          <Typography variant="body2">Vente: </Typography>
          <Typography variant="body2">
            {Math.round(repportFg?.tMise) || 0}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", gap: 18.3 }}>
          <Typography variant="body2">Paiment: </Typography>
          <Typography variant="body2">
            {Math.round(repportFg?.tGain) || 0}
          </Typography>
        </Box>

        <Box sx={{ padding: 3 }}>
          {repportFg?.data?.map((item, index) => (
            <Box key={index} mb={3}>
              <Typography variant="h6" sx={{ fontWeight: 700, fontSize: 18 }}>
                {item?.tirage}
              </Typography>
              <Grid
                container
                spacing={2}
                sx={{ justifyContent: "space-around", mt: 2 }}
              >
                <Grid item xs={4}>
                  <Typography variant="body2" sx={{ fontWeight: 500 }}>
                    Mise
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="body2" sx={{ fontWeight: 500 }}>
                    Gain
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="body2" sx={{ fontWeight: 500 }}>
                    Payé
                  </Typography>
                </Grid>
              </Grid>
              {item.fiches?.map((fiche, ficheIndex) => (
                <Grid
                  container
                  spacing={2}
                  sx={{ justifyContent: "space-around", mt: 1 }}
                  key={ficheIndex}
                >
                  <Grid item xs={4}>
                    <Typography variant="body2" sx={{ fontWeight: 500 }}>
                      {Math.round(fiche.mise) || 0}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="body2" sx={{ fontWeight: 500 }}>
                      {Math.round(fiche.win) || 0}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="body2" sx={{ fontWeight: 500 }}>
                      {fiche.is_pay ? "Oui" : "Non"}
                    </Typography>
                  </Grid>
                </Grid>
              ))}
            </Box>
          ))}
        </Box>
      </Box>

      <Box textAlign="center" mt={4}>
        <Button variant="outlined" onClick={handlePrint}>
          <Iconify icon={"eva:printer-fill"} />
        </Button>
      </Box>
    </Box>
  );
};

export default RepportFicheGagnant;
