import { useState } from "react";
import { Outlet } from "react-router-dom";
// @mui
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
// hooks
import useSettings from "../../hooks/useSettings";
import useResponsive from "../../hooks/useResponsive";
import useCollapseDrawer from "../../hooks/useCollapseDrawer";
// config
import { HEADER, NAVBAR } from "../../config";
//
import DashboardHeader from "./header";
import { useTheme } from "@mui/material/styles";
import cssStyles from "../../utils/cssStyles";
import NavbarVertical from "./navbar/NavbarVertical";
import NavbarHorizontal from "./navbar/NavbarHorizontal";
import Settings from "../../components/settings";

// ----------------------------------------------------------------------

const MainStyle = styled("main", {
  shouldForwardProp: (prop) => prop !== "collapseClick",
})(({ collapseClick, theme }) => ({
  flexGrow: 1,
  paddingTop: HEADER.MOBILE_HEIGHT + 24,
  paddingBottom: HEADER.MOBILE_HEIGHT + 24,
  backgroundColor: theme.palette.background.default,
  [theme.breakpoints.up("lg")]: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
    paddingBottom: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
    transition: theme.transitions.create("margin-left", {
      duration: theme.transitions.duration.shorter,
    }),
    ...(collapseClick && {
      marginLeft: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
    }),
  },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const { collapseClick, isCollapse } = useCollapseDrawer();

  const { themeLayout } = useSettings();

  const theme = useTheme();

  const isDesktop = useResponsive("up", "lg");

  const [open, setOpen] = useState(false);

  const verticalLayout = themeLayout === "vertical";

  return (
    <>
      <DashboardHeader
        onOpenSidebar={() => setOpen(true)}
        verticalLayout={verticalLayout}
      />
      {/* {!isDesktop && (
          <NavbarVertical
            isOpenSidebar={open}
            onCloseSidebar={() => setOpen(false)}
          />
        )} */}

      {/* <Settings /> */}

      <Box
        component="main"
        sx={{
          pt: `${HEADER.MOBILE_HEIGHT}px`,
          // pb: `${HEADER.MOBILE_HEIGHT + 10}px`,
          ...cssStyles(theme).bgImage({ url: "/static/assets/bg_card.png" }),
        }}
      >
        <Outlet />
      </Box>
    </>
  );
}
