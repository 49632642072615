export function extractErrorMessage (error) {
  if (error.response?.data) {
    if (typeof error.response.data === "string") {
      return error.response.data;
    } else if (Array.isArray(error.response.data)) {
      return error.response.data[0];
    } else if (error.response.data.detail) {
      return error.response.data.detail;
    }
  } else if (error.response?.error) {
    if (typeof error.response.error === "string") {
      return error.response.error;
    } else if (Array.isArray(error.response.error)) {
      return error.response.error[0];
    } else if (error.response.error.detail) {
      return error.response.error.detail;
    }
  }

  return "An error occurred. Please try again.";
};


export function extractErrorTicketMessage (e) {
  return e?.detail || e?.data || e?.error || e?.message ||  "An error occurred. Please try again.";
};