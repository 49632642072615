import { createSlice } from "@reduxjs/toolkit";

// ----------------------------------------------------------------------

const initialState = {
  selectedTirages: [],
};

const slice = createSlice({
  name: "selectiontirage",
  initialState,
  reducers: {
    toggleTirage: (state, action) => {
      const value = action.payload;
      if (state.selectedTirages.includes(value)) {
        state.selectedTirages = state.selectedTirages.filter(
          (tirage) => tirage !== value
        );
      } else {
        state.selectedTirages.push(value);
      }
    },
    resetTirages: (state) => {
      state.selectedTirages = [];
    },
  },
});

// Reducer
export default slice.reducer;
export const {
  toggleTirage,
  resetTirages,
} = slice.actions;
// ----------------------------------------------------------------------
